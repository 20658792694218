/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  PackageContainer,
  TitleSection,
  FeedbackSection,
  StatusesContainer,
  StatusSection,
  ScrollableText,
  TitleText,
  NormalText,
  DateText,
  FeedbackText,
  StatusText,
  StatusCheckIcon,
  StatusDotIcon,
} from "./PackageSection.elements";

const DefaultPackageSection = (props) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1008);

    useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth <= 1008);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => window.removeEventListener("resize", handleResize);
      }, []);

  const getStatusIcon = (currentStatus, section) => {
    const allStatuses = ["Uploaded", "Edited", "Tagged", "Distributed"];

    const statusMapping = {
      Uploaded: ["READY_FOR_CAT_DV", "CAT_DV_INGESTED", "UPLOADED"],
      Edited: ["EDITED"],
      Tagged: ["TAGGED"],
      Distributed: ["PUBLISHED"],
    };

    const achievedIndex = allStatuses.findIndex(
      (stage) =>
        statusMapping[stage]?.includes(currentStatus)
    );

    const sectionIndex = allStatuses.indexOf(section);

    return achievedIndex >= sectionIndex ? (
      <StatusCheckIcon />
    ) : (
      <StatusDotIcon />
    );
  };

  const getStatusText = (text) => {
    return isMobileView ? text.charAt(0) : text;
  };

  return (
    <>
      <PackageContainer>
        <TitleSection>
          <ScrollableText>
            <TitleText>{props.packageName}</TitleText>
            <NormalText>{props.description}</NormalText>
            <DateText>Uploaded on {props.dateShot} CEST</DateText>
          </ScrollableText>
        </TitleSection>
        <FeedbackSection>
          <ScrollableText>
            <FeedbackText>Total size: {props.totalSize}</FeedbackText>
            <FeedbackText>Upload status: {props.status}</FeedbackText>
            <FeedbackText>
              Uploading time to server: {props.uploadTimeServer}
            </FeedbackText>
            <FeedbackText>
              Uploading time to back balze: {props.uploadTimeProvider}
            </FeedbackText>
            <FeedbackText>
              Uploading speed to back blaze: {props.uploadSpeed} MB/sec
            </FeedbackText>
          </ScrollableText>
        </FeedbackSection>
        <StatusesContainer>
          <StatusSection>
            {getStatusIcon(props.status, "Uploaded")}
            <StatusText>{getStatusText("Uploaded")}</StatusText>
          </StatusSection>
          <StatusSection>
            {getStatusIcon(props.status, "Edited")}
            <StatusText>{getStatusText("Edited")}</StatusText>
          </StatusSection>
          <StatusSection>
            {getStatusIcon(props.status, "Tagged")}
            <StatusText>{getStatusText("Tagged")}</StatusText>
          </StatusSection>
          <StatusSection>
            {getStatusIcon(props.status, "Distributed")}
            <StatusText>{getStatusText("Distributed")}</StatusText>
          </StatusSection>
        </StatusesContainer>
      </PackageContainer>
    </>
  );
};

export default DefaultPackageSection;
