import styled from "@emotion/styled";;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  background-color: #2e2e2e;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 10px;
`;

// export const Circle = styled.div`
//   position: absolute;
//   border-radius: 50%;
//   background-color: "#808080";
// `;

export const PlaceholderText = styled.div`
  color: #ffffff;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  z-index: 1;
`;
