import styled from "@emotion/styled";
import {Button, IconButton } from "@mui/material";

export const AuthLogoBlock = styled.div`
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AuthTitleBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.lightgrey};
  padding-bottom: 25px;
  padding-top: 50px;
`;

export const TitleText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.lightgrey};
`;

export const RegularText = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text.lightgrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 30px;
  & > * {
    margin: 0 5px;
  }
`;

export const ErrorText = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 30px;
`;

const StyledButtonNoBg = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.lightgrey};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.hover.secondary};
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonNoBg = (props) => (
  <StyledButtonNoBg role="buttonNoBg" {...props} />
);

export const ButtonNoBgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing("large")};
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  padding-top: 10vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    margin: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
`;

export const SignInContainer = styled.div`
  background: ${({ theme }) => theme.palette.primary.dark2};
  padding-bottom: 50px;
  border-radius: 8px;
  width: 532px;
  max-width: 100%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0; /* Remove margin to ensure full width */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    max-width: 100%; /* Full width without max constraint */
    max-height: 100%; /* Full height without max constraint */
    border-radius: 0; /* Remove border radius for full screen */
    padding-bottom: 20px; /* Adjust padding for a better fit */
    overflow-y: auto; /* Allow scrolling on mobile if content exceeds height */
  }
`;

export const UserItem = styled.li`
  height: 80px;
  border-bottom: 2px solid;
`;

export const FormContainer = styled.div`
  max-width: 80%;
  margin: auto;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`;

export const FormGroup = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const FormLabel = styled.label`
  color: #fff; /* White color for the label */
  margin-bottom: 0px; /* Space between label and input */
`;

export const FormError = styled.p`
  color: #ff4d4d; /* White color for the error message */
  margin-bottom: -5px; /* Remove default margin */
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.input.dark1};
  font-size: 18px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export const SubmitButton = styled.button`
  border-radius: 14px;
  padding: 18px 96px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${({ buttonState }) => {
    switch (buttonState) {
      case "inactive":
        return "dark-grey";
      case "next":
        return "#40A31E";
      case "previous":
        return "#AEAEB0";
      case "auth":
        return "#5D62B6";
      default:
        return "lightgrey";
    }
  }};

  &:hover {
    transition: ${(props) =>
      props.disabled ? "inherit" : "all 0.3s ease-out"};
    background: ${(props) =>
      props.disabled ? "dark-grey" : "rgba(255, 255, 255, 0.5)"};
  }
`;

export const BackNavIcon = styled(IconButton)`
  margin-top: 16px;
  margin-left: 16px;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ChangePassFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 1008px) {
    flex-wrap: wrap;
  }
`;

export const IconWraper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AuthIcon = styled.svg`
  margin-top: 50px;
  width: 114px;
  height: 114px;
`;

export const ButtonIcon = styled.svg`
width: 20px;
height: 20px;
`;

export const EmailText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main}; /* Use theme color or any custom color */
`;


export const AddUserButton = styled(Button)`
  position: absolute;
  top: 70px;
  right: 30px;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  border-color: #fff; /* Changes the border color to match text */
  &:hover {
    background-color: rgba(93, 98, 182, 0.1); /* Change hover background color */
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;