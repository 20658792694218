import { Link } from "react-router-dom";
import styled from "@emotion/styled";

export const LogoLink = styled(Link)``;
export const LogoImg = styled.img`
  vertical-align: top;
  height: 70px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 80px;
  }
`;
