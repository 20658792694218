import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import StatService from '@/Service/StatService';

export const fetchStats = createAsyncThunk(
  'stats/fetchStats',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await StatService.getStats(params);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the uploads."));
      console.error("Error fetching data:", error);
      return rejectWithValue(error);
    }
  }
)

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    stats: {
      uploads: 0,
      clips: 0,
      avgRating: 0,
      commercialClips: 0,
      editorialClips: 0,
      resolution4K: 0,
      resolutionHD: 0,
      resolutionHDPlus: 0,
    },
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchStats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stats = action.payload;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

export const useStatsState = () => useSelector(state => state.stats);
export const useStatsActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    fetchStats: (params) => dispatch(fetchStats(params)),

  }), [dispatch]);
}

export default statsSlice.reducer;
