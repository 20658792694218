import React from "react";
import ForgotPasswordForm from "../../components/AuthForm/AuthSteps/ForgotPasswordForm";
import { CenterWrapper } from '../../components/AuthForm/AuthSteps/Auth.elements';

const ForgotPasswordPage = () => {
  return (
    <CenterWrapper>
      <ForgotPasswordForm />
    </CenterWrapper>
  );
};

export default ForgotPasswordPage;
