import React from "react";
import StatGroup from "../../components/Stats/StatGroup";

const HomePage = (props) => {
  
  return (
    <>
      <StatGroup currentUser={props.currentUser}/>
    </>
  );
};

export default HomePage;
