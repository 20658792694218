import styled from '@emotion/styled';
import { Global, css, useTheme } from '@emotion/react'
import { ChevronIcon } from '@/icons';

const GlobalStyle = () => {
  const theme = useTheme();

  return (<Global styles={css`
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: ${theme.fonts.body};
    }

    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      background-color: ${theme.palette.primary.dark}; /* Ensure color code is correct and without quotes */
      color: #fff;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .App {
      text-align: center;
    }

    .App-logo {
      height: 40vmin;
      pointer-events: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      .App-logo {
        animation: App-logo-spin infinite 20s linear;
      }
    }

    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `} />
  )
}

export const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const AppMain = styled.div`
  display: flex;
  flex-grow: 1;
`
export const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;

// export const Button = styled.button`
// border-radius: 3px;
// background: ${({ theme }) => theme.colors.primary};
// white-space: nowrap;
// padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
// font-size: ${({fontBig}) => (fontBig ? '24px' : '15px')};
// font-weight: bold;
// color: #fff;
// outline: none;
// border: none;
// cursor: pointer;

// &:hover {
//     transition: all 0.3s ease-out;
//     background: #fff;
//     background: ${({ theme }) => theme.colors.hover.primary};
// }

// @media screen and (max-width: 1008px){
//     width: 60%;
// }
// `;

const StyledAlert = styled.div`
  padding: 15px;
  border-radius: 0px, 3px;
  font-size: "16px";
  font-weight: bold;

  ${(props) =>
    props.type === 'success' &&
    css`
      background-color: #d4edda;
      color: #155724;
    `}

  ${(props) =>
    props.type === 'error' &&
    css`
      background-color: #f8d7da;
      color: #721c24;
    `}
`;

export const Alert = (props) => <StyledAlert role="alert" {...props}/>;

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darkening effect */
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: -1; /* Ensure it stays behind the loader */
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContentWrapper = styled.main`
  flex-grow: 1;
  padding: 20px;
`;

export const Spacer = styled.hr`
  flex-grow: 1;
  border: none;
`

export const MenuOutlinedDelimiter = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.dark2};
  margin: 8px 16px;
`

export const DropdownIcon = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  padding: 2px;
  ${({ isOpen }) => isOpen && css` rotate: 180deg; `}
`

export default GlobalStyle;
