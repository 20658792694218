import React from "react";
import {
  RatingContainer,
  RatingRow,
  RatingTitle,
  Star,
} from "./Rating.elements";

const Rating = (props) => {
  return (
    <RatingRow>
      <RatingTitle>Rating</RatingTitle>
      <RatingContainer>
        {[1, 2, 3, 4, 5].map((index) => (
          <Star key={index} isFilled={index <= props.rating}>
            ★
          </Star>
        ))}
      </RatingContainer>
    </RatingRow>
  );
};

export default Rating;
