import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";

import { UploadIcon, 
  UploadIconBlock, 
  FileItemContainer, 
  DeleteButton, 
  DropTitleText,
  DropNormalText,
  FileList,
  FilePath,
  FileSize
} from "../../UploadForm.elements";
import {FormatSize} from "../../../Util/Utils.elements";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #aeaeb0;
  color: #fff;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const Dropzone = (props) => {
  const [files, setFiles] = useState([]);
  // const [path, setPath] = useState([]);
  // console.log(path);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: ['text/csv', 'application/vnd.ms-excel']
    onDrop: (acceptedFiles) => {
      console.log("Files added:", acceptedFiles);
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      props.onFilesAdded(acceptedFiles); // Notify parent about accepted files
  },
  });

  // const handleDelete = (file) => {
  //   setFiles((prevFiles) => prevFiles.filter((f) => f !== file)); // Remove file from state
  // };

  const handleDelete = (file) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((f) => f !== file);
      // Notify parent about the updated files
      props.onFilesAdded(updatedFiles);
      return updatedFiles;
    });
  };

  const acceptedFileItemsWithDel = files.map((file) => (
    <FileItemContainer key={file.path}>
      <FilePath>{file.path}</FilePath>
      <FileSize><FormatSize sizeInBytes={file.size} /></FileSize>
      <DeleteButton onClick={() => handleDelete(file)}>Delete</DeleteButton>
    </FileItemContainer>
  ))

  console.log(acceptedFileItemsWithDel ? acceptedFileItemsWithDel : []);

  return (
    <div>
      <div>
        <section>
          <DropContainer
            {...getRootProps({
              className: "dropzone",
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <DropTitleText>Add your assets</DropTitleText>
              <UploadIconBlock>
              <UploadIcon />
              </UploadIconBlock>
              <DropNormalText>(Drag your files here)</DropNormalText>
          </DropContainer>
          <div style={{ padding: 5 }}></div>
          <FileList>
            {acceptedFileItemsWithDel}
          </FileList>
          <div style={{ padding: 10 }}></div>
        </section>
      </div>
    </div>
  );
};
