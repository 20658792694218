import { PlusIcon } from "@/icons";
import { Button, IconButton } from "@mui/material";
import { useIsMobile } from "../hooks";

export const UploadButton = (props) => {
  const { handleOpenModal } = props;
  const isMobile = useIsMobile();

  return isMobile ? (
    <IconButton onClick={handleOpenModal} color="secondary">
      <PlusIcon />
    </IconButton>
  ) : (
    <Button onClick={handleOpenModal} color="secondary">
      UPLOAD CLIPS
    </Button>
  )
}
