import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropzone } from "./DropZone";
import {
  FormButton,
  UploadTitleBlock,
  UploadTitleText,
  ButtonWrapper
} from "../../UploadForm.elements";

const VideoUpload = (props) => {
  const navigate = useNavigate();
  const [filesAdded, setFilesAdded] = useState(false);

  const handleNext = () => {
    navigate("/metadataForm");
  };

  const handleFilesAdded = (files) => {
    console.log("handleFilesAdded called with:", files);
    props.onFilesUpdate(files);
    setFilesAdded(files.length > 0);
    console.log("filesAdded state updated to:", files.length > 0);
  };

  return (
    <>
      <UploadTitleBlock>
        <UploadTitleText textColor="grey">UPLOAD CLIPS:</UploadTitleText>
        <UploadTitleText textColor="green">STEP 1/2</UploadTitleText>
      </UploadTitleBlock>
      <Dropzone onFilesAdded={handleFilesAdded} />
      <ButtonWrapper>
      <FormButton
        buttonState={filesAdded ? "next" : "inactive"}
        disabled={!filesAdded}
        onClick={handleNext}
      >
        Continue
      </FormButton>
      </ButtonWrapper>
    </>
  );
};

export default VideoUpload;
