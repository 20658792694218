import {
  Navbar,
  Header,
  Footer,
  GlobalLoader,
  GlobalAlert,
} from "@/components";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import GlobalStyle, { AppLayout, AppMain, AppContent } from "./globalStyles";
import HomePage from "./pages/HomePage/HomePage";
import UploadsPage from "./pages/UploadsPage/UploadsPage";
import SignInPage from "./pages/AuthPages/SIgnInPage";
import ForgotPasswordPage from "./pages/AuthPages/ForgotPasswordPage";
import PasswordSentPage from "./pages/AuthPages/PasswordSentPage";
import UploadInputGroup from "./components/UploadForm/UploadInputGroup";
import ProgressBar from "./components/UploadForm/ProgressBar";
import RoleProtectedRoute from "./components/AuthForm/RoleProtectedRoute";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import { useModalManager } from "./components/hooks/useModalManager";
import { useUploadManager } from "./components/hooks/useUploadManager";
import ClipPage from "./pages/ClipPage/ClipsPage";
import { CssBaseline } from "@mui/material";
import {
  useAuthState,
  useListenToAuthChanges,
  useMobileMenuActions,
} from "@/store";
import ChangePasswordGroup from "./components/Profile/ChangePassword/ChangePasswordGroup";
import AddNewUserPage from "./pages/AddUserPage/AddNewUserPage";

function App() {
  const { currentUser } = useAuthState();

  useListenToAuthChanges();

  const { setMobileMenuOpen } = useMobileMenuActions();
  // TODO: check is this states are useful
  const [cloudFolder /* setCloudFolder */] = useState("");

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const { showUploadModal, toggleUploadModal } =
    useModalManager(closeMobileMenu);

  const { showChangePasswordModal, toggleChangePasswordModal } =
    useModalManager(closeMobileMenu);

  const { showAddUserModal, toggleAddUserdModal } =
    useModalManager(closeMobileMenu);

  const {
    formData,
    uploadProgress,
    isUploading,
    uploadStatus,
    handleFilesUpdate,
    handleMetadataUpdate,
    handleCancel,
  } = useUploadManager(toggleUploadModal);

  const handleClose = (toggleModal) => {
    toggleModal();
    setMobileMenuOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <GlobalAlert />
      <GlobalLoader />
      <AppLayout>
        {currentUser && <Header handleOpenModal={toggleUploadModal} />}
        {showUploadModal && (
          <UploadInputGroup
            onFilesUpdate={handleFilesUpdate}
            uploadMetadata={handleMetadataUpdate}
            handleClose={() => handleClose(toggleUploadModal)}
            show={showUploadModal}
          />
        )}
        {isUploading && (
          <ProgressBar
            packageName={formData.uploadMetadata.packageName}
            progress={uploadProgress}
            status={uploadStatus}
            folder={cloudFolder}
            cancel={handleCancel}
          />
        )}

        {showChangePasswordModal && (
          <ChangePasswordGroup
            show={showChangePasswordModal}
            handleClose={() => handleClose(toggleChangePasswordModal)}
          />
        )}

        {showAddUserModal && (
          <AddNewUserPage
            show={showAddUserModal}
            handleClose={() => handleClose(toggleAddUserdModal)}
          />
        )}

        <AppMain>
          {currentUser && <Navbar />}
          <AppContent>
            <Routes>
              <Route
                path="/"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <HomePage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/uploads"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <UploadsPage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/clips"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <ClipPage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/adduser"
                element={
                  <RoleProtectedRoute allowedRoles={["manager", "admin"]}>
                    <AddUserPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/signin"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <SignInPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/resetpassword"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <ForgotPasswordPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/passwordsent"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <PasswordSentPage />
                  </RoleProtectedRoute>
                }
              />
            </Routes>
          </AppContent>
        </AppMain>
        <Footer />
      </AppLayout>
    </>
  );
}

export default App;
