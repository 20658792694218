import React from "react";
import Counter from "./Counter";
import { CounterStatsContainer, StatsTitle, CounterBlock } from "./CountersBlock.elements";
// import { Container } from "../../../globalStyles";
// import { DashboardContainer } from "./CountersBlock.elements";

const CounterStats = (props) => {

  return (
    <CounterStatsContainer>
        <StatsTitle>
            Quantity
        </StatsTitle>
        <CounterBlock>
      <Counter count={props.uploads} title={"Uploads"} />
      <Counter count={props.clips} title={"Clips"} />
      {/* <Counter count={20} title={"VOs"} /> */}
      </CounterBlock>
    </CounterStatsContainer>
  );
};

export default CounterStats;
