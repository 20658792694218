import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alert: { type: '', message: '' },
  },
  reducers: {
    addAlert: (state, action) => {
      state.alert = action.payload;
    },
    hideAlert: (state) => {
      state.alert = { type: '', message: '' };
    }
  },
});

export const { addAlert, hideAlert } = alertSlice.actions;

export const showAlert = (type, message) => (dispatch, getState) => {
  dispatch(addAlert({ type, message }))
  setTimeout(() => {
    dispatch(hideAlert());
  }, 5000);
};

export const useAlertState = () => useSelector((state) => state.alert);
export const useAlertActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    showAlert: (type, message) => dispatch(showAlert(type, message)),
    hideAlert: () => dispatch(hideAlert()),
  }), [dispatch]);
}

export default alertSlice.reducer;
