import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import ClipService from '@/Service/ClipService';

export const fetchClips = createAsyncThunk(
  'clips/fetchClips',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      console.log("ClipGroup params: ", params);
      const response = await ClipService.getClips(params);
      console.log("ClipGroup response: ", response.data.content);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the uploads."))
      console.error("Error fetching data:", error);
      return rejectWithValue(error);
    }
  }
)

const clipsSlice = createSlice({
  name: 'clips',
  initialState: {
    clips: [],
    totalPages: 0,
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchClips.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClips.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clips = action.payload.content;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchClips.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

export const useClipsState = () => useSelector(state => state.clips);
export const useClipsActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    fetchClips: (params) => dispatch(fetchClips(params)),

  }), [dispatch]);
}

export default clipsSlice.reducer;
