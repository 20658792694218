import React, { useState, useEffect } from "react";
import { useClipsActions, useClipsState, useContributorsState } from "@/store";
import { GridContainer } from "./Clip.elements";
import ClipCard from "./ClipCard";
import { Container } from "../../globalStyles";
import ClipSearchForm from "./ClipSearchForm";
import Pagination from "../Util/Pagination";

const ClipsGroup = (props) => {
  const { selectedContributorId }  = useContributorsState();
  const { clips, totalPages } = useClipsState();
  const { fetchClips } = useClipsActions();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [searchParams, setSearchParams] = useState({
    category: null,
    fileName: null,
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    let params = {
      page: currentPage,
      pageSize,
      contributors: selectedContributorId,
      ...searchParams,
    };
    fetchClips(params);
  }, [currentPage, pageSize, selectedContributorId, searchParams, fetchClips]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    console.log("newPageSize: ", newSize);
    setPageSize(newSize);
    setCurrentPage(0);
  };

  const handleSearch = (newSearchParams) => {
    setSearchParams(newSearchParams);
    setCurrentPage(0);
  };

  return (
    <Container>
      <ClipSearchForm onSearch={handleSearch} />
      <GridContainer>
        {clips.map((file) => (
          <ClipCard key={file.fileName} file={file} />
        ))}
      </GridContainer>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </Container>
  );
};

export default ClipsGroup;
