import React, { useEffect } from "react";
import { useContributorsActions, useContributorsState } from "@/store";
import { IconWraper, ButtonIcon, AddUserButton } from '@/components/AuthForm/AuthSteps/Auth.elements'
import { PlusIcon } from '@/icons'
import { useNavigate } from "react-router-dom";

import {
  DeleteButton,
  FormTitle,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableHeader,
  ActionsTableHeader,
  BtnTableCell,
} from "../Util/Form.elements";

const ListUsers = () => {
  const { contributors } = useContributorsState();
  const { fetchContributors, deleteContributor } = useContributorsActions();
  const navigate = useNavigate();

  useEffect(() => {
    fetchContributors();
  }, [fetchContributors]);

//  const getHighestRole = (roles) => {
//    if (roles.includes("admin")) return "admin";
//    if (roles.includes("manager")) return "manager";
//    if (roles.includes("contributor")) return "contributor";
//    return "";
//  };

  return (
    <>
    <AddUserButton variant="outlined" onClick={() => navigate("/addnewuser")} startIcon={
      <IconWraper>
        <ButtonIcon as={PlusIcon} />
      </IconWraper>
      }>
      Add User
    </AddUserButton>

    <TableContainer>
      <FormTitle>Users:</FormTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Role</TableHeader>
            <ActionsTableHeader>Actions</ActionsTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {contributors.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <BtnTableCell>
                <DeleteButton onClick={() => deleteContributor(user)}>
                  Delete
                </DeleteButton>
              </BtnTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default ListUsers;
