import http from "./http-common";
import { getAuthToken } from "./authService";

const createUser = async (user) => {
  try {
    const token = await getAuthToken();
    console.log("User token is: ", token);

    // Make the request with the token in the Authorization header
    const response = await http.put("api/v1/user", user, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token here
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

const deleteUser = async (userId) => {
  const token = await getAuthToken();

  try {
    const response = await http.delete('/api/v1/user/' + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;  // Returning the status code of the deletion
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

const UserService = {
  createUser,
  deleteUser
};

export default UserService;
