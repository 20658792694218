import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from '@/store';
import { Loader } from '@/components';

const RoleProtectedRoute = ({ children, allowedRoles, allowUnauthenticated = false }) => {
    const { currentUser, fetch: { status } } = useAuthState();
    const { role } = currentUser || {};

    if (status !== 'succeeded') return <Loader />;

    if (allowUnauthenticated) {
        if (currentUser) {
            return <Navigate to="/" replace />;
        } else {
            return children;
        }
    }

    if (!currentUser) {
        return <Navigate to="/signin" replace />;
    }

    const isAllowed = role && allowedRoles?.includes?.(role);
    return isAllowed ? children : <Navigate to="/signin" replace />;
};

export default RoleProtectedRoute;
