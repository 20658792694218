import React from 'react';
import {
  PaginationContainer,
  PaginationButton,
  PageSizeControl,
  PageSizeLabel,
  PageSizeSelect,
  PageAmountText,
} from '../PackagesSection/PackageSection.elements';

const Pagination = ({
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <>
      <PaginationContainer>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 0}>
          Previous
        </PaginationButton>
        <PageAmountText>
          Page {currentPage + 1} of {totalPages}
        </PageAmountText>
        <PaginationButton
          onClick={handleNextPage}
          disabled={currentPage >= totalPages - 1}
        >
          Next
        </PaginationButton>
      </PaginationContainer>

      <PageSizeControl>
        <PageSizeLabel>Page Size:</PageSizeLabel>
        <PageSizeSelect value={pageSize} onChange={onPageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </PageSizeSelect>
      </PageSizeControl>
    </>
  );
};

export default Pagination;
