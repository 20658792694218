import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAlertActions, useLoadingActions } from "@/store";
import PasswordField from "../../../PasswordField";
import { doPasswordChange } from "../../../../firebase/auth";
import {
  SignInContainer,
  FormContainer,
  AuthTitleBlock,
  TitleText,
  ChangePassFormWrapper,
  ButtonWrapper,
  SubmitButton,
  ErrorText,
} from "../../../AuthForm/AuthSteps/Auth.elements";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [currentPasswordError, setCurrentPasswordError] = useState();

  const { showLoading, hideLoading } = useLoadingActions();
  const { showAlert, hideAlert } = useAlertActions();

  const onSubmit = async (data) => {
    try {
      showLoading();
      hideAlert();
      await doPasswordChange(data.currentPassword, data.newPassword); // Call the password change function
      hideLoading();
      navigate("/changepasswordconfirmation");
      showAlert("success", "Password changed successfully.");
    } catch (error) {
      hideLoading();
      console.log("ChangePassword.js error", error.message);
      showAlert("error", "Failed to change password", error.message);
      setCurrentPasswordError("Current password is incorrect")
    }
  };

  return (
    <SignInContainer>
      <FormContainer>
        <AuthTitleBlock>
          <TitleText>CHANGE PASSWORD</TitleText>
        </AuthTitleBlock>

        {currentPasswordError && (
          <ErrorText>
          {currentPasswordError}
          </ErrorText>
          )
        }




        <form onSubmit={handleSubmit(onSubmit)}>
          <ChangePassFormWrapper>
            {/* Replace Current Password Field */}
            <PasswordField
              label="Current Password"
              fieldName="currentPassword"
              register={register}
              errors={errors}
              validation={{
                required: "Current password is required",
              }}
            />

            {/* Replace New Password Field */}
            <PasswordField
              label="New Password"
              fieldName="newPassword"
              register={register}
              errors={errors}
              validation={{
                required: "New password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
            />

            {/* Replace Confirm New Password Field */}
            <PasswordField
              label="Confirm New Password"
              fieldName="confirmNewPassword"
              register={register}
              errors={errors}
              validation={{
                required: "Please confirm your new password",
                validate: (value) =>
                  value === watch("newPassword") || "Passwords do not match",
              }}
            />
          </ChangePassFormWrapper>

          <ButtonWrapper>
            <SubmitButton
              buttonState="auth"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Change Password
            </SubmitButton>
          </ButtonWrapper>
        </form>
      </FormContainer>
    </SignInContainer>
  );
};

export default ChangePasswordForm;
