import { useMobileMenuActions, useMobileMenuState } from "@/store/mobileMenuSlice";
import { DashboardIcon, UploadsIcon, ClipsIcon, ContributorsIcon } from "@/icons"

import {
  NavPlaceholder,
  Nav,
  NavIcon,
  NavLink,
  NavDivider,
} from "./Navbar.elements";
import { useAuthState } from "@/store";


export const Navbar = () => {
  const { currentUser } = useAuthState();
  const { role } = currentUser || {};
  const isAdmin = role === "admin";
  const isManager = role === "manager";
  const { isMobileMenuOpen } = useMobileMenuState();
  const { toggleMobileMenu } = useMobileMenuActions();

  if (!currentUser) {
    console.log("current user is: ", currentUser);
    return null;
  } else {
    return (
      <NavPlaceholder>
        <Nav isMobileMenuOpen={isMobileMenuOpen}>

          <NavLink to="/" onClick={toggleMobileMenu} >
            <NavIcon as={DashboardIcon} />
            Dashboard
          </NavLink>
          <NavLink to="/uploads" onClick={toggleMobileMenu}>
            <NavIcon as={UploadsIcon} />
            Uploads
          </NavLink>
          <NavLink to="/clips" onClick={toggleMobileMenu}>
            <NavIcon as={ClipsIcon} />
            Clips
          </NavLink>

          {/* <NavLink to="/faq">
              <NavQuestionIcon />
              FAQ
            </NavLink> */}

          {(isAdmin || isManager) && (
            <>
              <NavDivider/>
              <NavLink to="/addUser" onClick={toggleMobileMenu}>
                <NavIcon as={ContributorsIcon} />
                Contributors
              </NavLink>
            </>
          )}
        </Nav>
      </NavPlaceholder>
    );
  }
};
