import styled from "@emotion/styled";

export const DeleteButton = styled.button`
  display: flex;
  background: #ff4d4d; /* Red background */
  border: none;
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.8rem;
  height: 100%; /* Ensure button height is consistent */
  align-self: center;
  &:hover {
    background: #ff1a1a; /* Darker red on hover */
  }
`;

export const FormContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 10px;
`;

export const TimeFormContainer = styled.div`
  max-width: 200px;
  margin: auto;
  padding: 10px;
  display: flex;
  align-items: flex-start; /* Align items to flex-start */
  justify-content: flex-start; /* Optional: Control horizontal alignment */
  flex-direction: column; /* Stack children vertically */
`;

export const TimeForm = styled.form`
  width: 100%;
`;

export const TimeSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  background-color: #2b2b2b;
  border: 1px solid #707070;
  color: #8f8f8f;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  option {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
  appearance: auto;
  &::placeholder {
    color: #fff;
  }
  `;

export const FormTitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out titles */
  margin-bottom: 20px;
  border-bottom: 2px solid #444; /* Add a border to separate sections */
  padding-bottom: 10px;
`;

export const FormTitle = styled.h2`
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem; /* Increase font size */
  margin: 0; /* Remove default margin */
`;

export const SectionDivider = styled.hr`
  border-top: none;
  height: 1px;
  background: linear-gradient(to right, transparent, #fff, transparent);
  margin: 40px 0; /* Vertical spacing around the divider */
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #3a3a3f;
  gap: 10px;
  padding: 20px;
  margin-top: 10px;

  @media (min-width: 1008px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const FormGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BtnFormGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 20%;
  gap: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%; /* Make the FormGroup take the full width */
  }
`;

export const FormLabel = styled.label`
  color: #fff; /* White color for the label */
  margin-bottom: 0px; /* Space between label and input */
`;

export const FormError = styled.p`
  color: #ff4d4d; /* White color for the error message */
  margin-bottom: -5px; /* Remove default margin */
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #f0f0f0;
  border: 1px solid #ccc; /* Added border to match the Select */
  font-size: ${({ theme }) => theme.fontSizes.medium}; /* Ensures consistent font size */
  //box-sizing: border-box; /* Ensures padding and border are included in the width */
`;

export const Select = styled.select`
  width: 100%;
  padding: 7px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #f0f0f0;
  border: 1px solid #ccc; /* Optional: border color to match the input */
  font-size: 15px; /* Optional: font size to match the input */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  appearance: none; /* Remove default dropdown arrow */
  background-size: 12px; /* Adjust size of dropdown arrow */
  appearance: auto;

  &:focus {
    outline: none;
    border-color: #007bff; /* Optional: change border color on focus */
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.25); /* Optional: add shadow on focus */
  }
`;

export const FormButton = styled.button`
  background: #40A31E;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 20px 0px;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`;

export const RowFormGroup = styled(FormGroup)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 20px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

export const TableHead = styled.thead`
  background-color: #3a3a3f;
  color: #fff;
`;

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  //border-bottom: 2px solid #ddd;
`;

export const ActionsTableHeader = styled.th`
  padding: 10px;
  text-align: center;
  //border-bottom: 2px solid #ddd;
`;

export const TableBody = styled.tbody`
  //background-color: #5F5F68;
  color: #fff;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #20243C;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  //border-bottom: 1px solid #ddd;
`;

export const BtnTableCell = styled.td`
  padding: 10px;
  display: flex;
  justify-content: center;
`;
