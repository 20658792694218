import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAdA4jhfOuxzB8s5BkTeLsxZ_JlpDXzv9A",
  authDomain: "clipnnuploader.firebaseapp.com",
  projectId: "clipnnuploader",
  storageBucket: "clipnnuploader.appspot.com",
  messagingSenderId: "1054193012080",
  appId: "1:1054193012080:web:42e86476c2ecd3970113b5",
  measurementId: "G-C0Y98VPJQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export {app, auth, firestore};