import { AccountIcon } from "@/icons";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { DropdownIcon } from "@/globalStyles";

export const SmallScreenSignOut = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
`;

export const UserTrigger = styled(Button)`
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 5px;
  min-width: auto;
  border-radius: 50%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 5px 10px;
    border-radius: 0;
  }
`

export const UserIcon = styled(AccountIcon)`
  width: 40px;
  height: 40px;
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const UserMenuInfo = styled.li`
  padding: 0 16px;
`

export const UserName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  line-height: 24px;
`;

export const UserRole = styled.div`
  color: ${({ theme }) => theme.palette.primary.light2};
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  font-weight: 400;
  line-height: 21px;
  text-transform: capitalize;
`

export const UserDropdownIcon = styled(DropdownIcon, { shouldForwardProp: (prop) => prop !== 'isOpen' })`
  margin-left: 4px;
`

export const UserMenuIcon = styled.svg`
  width: 20px;
  height: 20px;
`;
