import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const HeaderPlaceholder = styled.header`
  height: 70px;
`
export const HeaderContainer = styled.header`
  height: inherit;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  align-items: center;
  padding: 0 40px;
  gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 24px;
  }
`

export const MobileNavIcon = styled(IconButton)`
  display: block;
  font-size: 24px;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
