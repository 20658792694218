import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useModalManager(onModalToggle) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Toggle functions
  const toggleUploadModal = () => {
    if (!showUploadModal) {
      if (onModalToggle) onModalToggle();
      // When opening the modal, navigate to the correct path
      navigate("/videoUpload");
    } else {
      // When closing the modal, navigate back to the home or another safe route
      navigate(-1);
    }
    setShowUploadModal(!showUploadModal);
  };
  const toggleAuthModal = () => setShowAuthModal(!showAuthModal);

  const toggleChangePasswordModal = () => {
    if (!showChangePasswordModal) {
      if (onModalToggle) onModalToggle();
      // When opening the modal, navigate to the correct path
      navigate("/changepassword");
    } else {
      // When closing the modal, navigate back to the home or another safe route
      navigate(-1);
    }
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const toggleAddUserdModal = () => {
    if (!showAddUserModal) {
      if (onModalToggle) onModalToggle();
      // When opening the modal, navigate to the correct path
      navigate("/addUser");
    } else {
      // When closing the modal, navigate back to the home or another safe route
      navigate(-1);
    }
    setShowAddUserModal(!showAddUserModal);
  };

  // Automatically control modal visibility based on route
  useEffect(() => {
    if (
      location.pathname === "/videoUpload" ||
      location.pathname === "/metadataForm"
    ) {
      setShowUploadModal(true);
    } else {
      setShowUploadModal(false);
    }

    if (
      location.pathname === "/changepassword" ||
      location.pathname === "/changepasswordconfirmation" ||
      location.pathname === "/changepasswordError"
    ) {
      setShowChangePasswordModal(true);
    } else {
      setShowChangePasswordModal(false);
    }

    if (
      location.pathname === "/addnewuser"
    ) {
      setShowAddUserModal(true);
    } else {
      setShowAddUserModal(false);
    }

    if (
      location.pathname === "/signin" ||
      location.pathname === "/resetpassword"
    ) {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location]);

  return {
    showUploadModal,
    showAuthModal,
    showChangePasswordModal,
    showAddUserModal,
    toggleUploadModal,
    toggleAuthModal,
    toggleChangePasswordModal,
    toggleAddUserdModal,
    setShowAddUserModal,
  };
}
