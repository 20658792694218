import React from "react";
import ClipsGroup from "../../components/Clips/ClipsGroup";

const ClipsPage = (props) => {
  return (
    <>
      <ClipsGroup currentUser={props.currentUser}/>
    </>
  );
};

export default ClipsPage;
