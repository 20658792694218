import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "@/firebase/firebase";
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import UserService from "@/Service/UserService";

export const fetchContributors = createAsyncThunk(
  'contributors/fetchContributors',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      // dispatch(hideAlert());
      const usersCollection = collection(firestore, "users");
      const userData = await getDocs(usersCollection);
      const contributors = userData.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      dispatch(hideLoading());
      return contributors;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "Error fetching users."));
      console.error("Error fetching users:", error.message);
      return rejectWithValue(error);
    }
  }
)

export const createContributor = createAsyncThunk(
  'contributors/createContributor',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const data = await UserService.createUser(payload);
      dispatch(hideLoading());
      dispatch(showAlert("success", "User created successfully!"));
      return data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "Failed to create user."));
      console.error("Error:", error);
      return rejectWithValue(error);
    }
  }
)

export const deleteContributor = createAsyncThunk(
  'contributors/deleteContributor',
  async (contributor, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      await UserService.deleteUser(contributor.id);
      dispatch(hideLoading());
      dispatch(showAlert("success", `User ${contributor.name} deleted successfully!`));
      console.log(`User with ID ${contributor.id} successfully deleted.`);
      return contributor.id;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "Error deleting user."));
      console.error("Error deleting user:", error.message);
      return rejectWithValue(error);
    }
  }
)

const contributorsSlice = createSlice({
  name: 'contributors',
  initialState: {
    selectedContributorId: '',
    contributors: [],
    fetch: {
      status: 'idle', // idle | loading | succeeded | failed
      error: null,
    },
    create: {
      status: 'idle', // idle | loading | succeeded | failed
      error: null,
    },
    delete: {
      status: 'idle', // idle | loading | succeeded | failed
      error: null,
    }
  },
  reducers: {
    selectContributorId: (state, action) => {
      state.selectedContributorId = action.payload;
    }
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchContributors.pending, (state) => {
        state.fetch.status = 'loading';
      })
      .addCase(fetchContributors.fulfilled, (state, action) => {
        state.fetch.status = 'succeeded';
        state.contributors = action.payload;
      })
      .addCase(fetchContributors.rejected, (state, action) => {
        state.fetch.status = 'failed';
        state.fetch.error = action.error.message;
      })

      .addCase(createContributor.pending, (state) => {
        state.create.status = 'loading';
      })
      .addCase(createContributor.fulfilled, (state, action) => {
        state.create.status = 'succeeded';
        console.log('PAYLOAD', action.payload);
        state.contributors = [action.payload, ...state.contributors];
      })
      .addCase(createContributor.rejected, (state, action) => {
        state.create.status = 'failed';
        state.create.error = action.error.message;
      })

      .addCase(deleteContributor.pending, (state) => {
        state.delete.status = 'loading';
      })
      .addCase(deleteContributor.fulfilled, (state, action) => {
        state.delete.status = 'succeeded';
        state.contributors = state.contributors.filter(contributor => contributor.id !== action.payload);
      })
      .addCase(deleteContributor.rejected, (state, action) => {
        state.delete.status = 'failed';
        state.delete.error = action.error.message;
      });
  }
});

export const { selectContributorId } = contributorsSlice.actions;

export const useContributorsState = () => useSelector(state => state.contributors);
export const useContributorsActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    selectContributorId: (contributorId) => dispatch(selectContributorId(contributorId)),
    fetchContributors: () => dispatch(fetchContributors()),
    createContributor: (contributor) => dispatch(createContributor(contributor)),
    deleteContributor: (contributor) => dispatch(deleteContributor(contributor)),

  }), [dispatch]);
}

export default contributorsSlice.reducer;
