import React, { useState, useEffect } from "react";
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormError,
} from "../Util/Form.elements";
import {
  SearchFormWrapper,
  SearchInput,
  SearchDatePickerInput,
  SearchButtonWrapper,
  SearchSelect
} from "./SearchForm.elements";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { FormButton } from "../UploadForm/UploadForm.elements";
import countriesData from "../Util/countries.json";

const SearchForm = ({ onSearch }) => {
  const [countries, setCountries] = useState([]);
  // TODO: check usefulness of this state
  const [/* selectedCountry */, setSelectedCountry] = useState("");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm();

  useEffect(() => {
    // Initialize countries from JSON
    setCountries(countriesData);
  }, []);

  const handleReset = () => {
    reset();
    const resetData = {
      category: null,
      fileName: null,
      fromDate: null,
      toDate: null,
    };
    onSearch(resetData);
  };

  const handleCountryChange = (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setValue("country", value); // Update react-hook-form value
    clearErrors("country"); // Clear error when user selects a valid option
  };

  const onSubmit = (data) => {
    console.log("search form submitted:", data);
    onSearch(data); // Pass the form data to the parent component
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SearchFormWrapper>
          <FormGroup>
            <FormLabel>Package Name:</FormLabel>
            <FormError>{errors.packageName?.message}</FormError>
            <SearchInput
              {...register("packageName")}
              placeholder="Enter search term..."
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Country</FormLabel>
              <FormError>{errors.country?.message}</FormError>
            <SearchSelect
              {...register("country")}
              onChange={handleCountryChange}
              defaultValue=""
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </SearchSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel>From:</FormLabel>
            {errors.dateShot && (
              <FormError>{errors.fromDate.message}</FormError>
            )}
            <Controller
              name="fromDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(fromDate) => field.onChange(fromDate)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select from date"
                  customInput={<SearchDatePickerInput />}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>To:</FormLabel>
            {errors.dateShot && <FormError>{errors.toDate.message}</FormError>}
            <Controller
              name="toDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(toDate) => field.onChange(toDate)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select to date"
                  customInput={<SearchDatePickerInput />}
                />
              )}
            />
          </FormGroup>
          <SearchButtonWrapper>
            <FormButton buttonState="next" type="submit">
              Search
            </FormButton>
            <FormButton
              buttonState="previous"
              type="button"
              onClick={handleReset}
            >
              Reset
            </FormButton>
          </SearchButtonWrapper>
        </SearchFormWrapper>
      </form>
    </FormContainer>
  );
};

export default SearchForm;
