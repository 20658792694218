import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import UploadService from "../../Service/UploadService";
import { useNavigate } from "react-router-dom";

export function useUploadManager(closeModal) {
  const [formData, setFormData] = useState({
    files: [],
    uploadMetadata: {},
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const navigate = useNavigate();

  const handleFilesUpdate = (newFiles) => {
    setFormData((prevState) => {
      const existingFileNames = prevState.files.map(file => file.name);
      const filteredNewFiles = newFiles.filter(file => !existingFileNames.includes(file.name));

      return {
        ...prevState,
        files: [...prevState.files, ...filteredNewFiles],
      };
    });
  };

  const handleMetadataUpdate = (uploadMetadata) => {
    const uploadId = uuidv4(); // Generate uploadId here
    const updatedMetadata = { ...uploadMetadata, uploadId };

    setFormData((prevState) => ({
      ...prevState,
      uploadMetadata: updatedMetadata,
    }));

    const currentFormData = {
      ...formData,
      uploadMetadata: updatedMetadata,
    };

    if (closeModal) closeModal();
    // Start the upload process
    setUploadStatus("");
    setIsUploading(true);

    UploadService.upload(currentFormData, (progress, remainingTime) => {
      setUploadProgress(progress);
      setRemainingTime(remainingTime);
    })
      .then(() => {
        setUploadStatus("Successfully uploaded");
        setTimeout(() => {
          setIsUploading(false);
        }, 5000);
        navigate("/");
      })
      .catch(() => {
        setUploadStatus("Failed to upload");
        setTimeout(() => {
          setIsUploading(false);
        }, 5000);
        navigate("/");
      });
  };

  const handleCancel = () => {
    if (!isValidUUID(formData.uploadMetadata.uploadId)) {
      console.error("Invalid UUID format");
      return;
    } else {
      setUploadStatus("Canceled");
      setTimeout(() => {
        setIsUploading(false);
      }, 5000);
      UploadService.cancel(formData.uploadMetadata.uploadId);
      navigate("/");
    }
  };

  const isValidUUID = (uuid) => {
    const regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return regex.test(uuid);
  };

  return {
    formData,
    uploadProgress,
    remainingTime,
    isUploading,
    uploadStatus,
    handleFilesUpdate,
    handleMetadataUpdate,
    handleCancel,
  };
}
