import React from 'react';
import {
    SignInContainer,
    SubmitButton,
    RegularText,
    TitleText,
    ButtonWrapper,
    AuthTitleBlock,
    IconWraper,
    AuthIcon,
    EmailText,
    FormContainer
  } from "./Auth.elements";
  import { Mail } from "@/icons";
  import { useNavigate, useLocation } from "react-router-dom";


const PasswordSent = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {}; 

    return (
      <SignInContainer>
          <IconWraper>
          <AuthIcon as={Mail} />
          </IconWraper>
          <FormContainer>
          <AuthTitleBlock>
            <TitleText>PASSWORD CHANGED!</TitleText>
          </AuthTitleBlock>
          <RegularText>
          An email has been sent to <EmailText>{email}</EmailText> with instructions for resetting your password.
          </RegularText>
          </FormContainer>
          <ButtonWrapper>
            <SubmitButton buttonState="auth" onClick={() => navigate("/signin")}>
            Continue</SubmitButton>
          </ButtonWrapper>
      </SignInContainer>
    );
}

export default PasswordSent