import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import UploadService from '@/Service/UploadService';

export const fetchPackages = createAsyncThunk(
  'packages/fetchPackages',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await UploadService.getFiles(params);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the uploads."))
      console.error("Error fetching data:", error);
      return rejectWithValue(error);
    }
  }
)

const packagesSlice = createSlice({
  name: 'packages',
  initialState: {
    packages: [],
    totalPages: 0,
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.packages = action.payload.content;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

export const usePackagesState = () => useSelector(state => state.packages);
export const usePackagesActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    fetchPackages: (params) => dispatch(fetchPackages(params)),

  }), [dispatch]);
}

export default packagesSlice.reducer;
