import React from "react";
import PieChart from "./PieChart";
import { ChartsBlock } from "./Counter/CountersBlock.elements";

const PieCharts = (props) => {

  console.log("PieCharts data:", props.stats)

  const resolutionData = [
    {
      id: "resolution4K",
      label: "4K",
      value: props.stats.resolution4K || 0,
      color: "#40A31E", // Green color
    },
    {
      id: "resolutionHDPlus",
      label: "HD+",
      value: props.stats.resolutionHDPlus || 0,
      color: "#FF8000", // Orange color
    },
    {
      id: "resolutionHD",
      label: "<HD",
      value: props.stats.resolutionHD || 0,
      color: "#FF0000", // Red color
    },
  ];

  const typeData = [
    {
      id: "commercialClips",
      label: "commercial",
      value: props.stats.commercialClips || 0,
      color: "#3300FF",
    },
    {
      id: "editorialClips",
      label: "editorial",
      value: props.stats.editorialClips || 0,
      color: "#A600FF", // Orange color
    }
  ];

  return (
    <ChartsBlock>
    <PieChart data={resolutionData}/>
    <PieChart data={typeData}/>
    </ChartsBlock>
  )
}

export default PieCharts