// export const FormatRemainingTime = (seconds) => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const secs = Math.floor(seconds % 60);

//     return [
//       hours.toString().padStart(2, "0"),
//       minutes.toString().padStart(2, "0"),
//       secs.toString().padStart(2, "0"),
//     ].join(":");
//   };

export const FormatRemainingTime = (input) => {

  // Check if input is an object and extract milliseconds
  const milliseconds = input && typeof input === "object" ? input.input : input;

  // Validate milliseconds
  if (typeof milliseconds !== "number" || isNaN(milliseconds)) {
    //console.error("Invalid milliseconds value:", milliseconds);
    return "Not available";
  }

  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);

  const minutes = Math.floor((seconds % 3600) / 60);

  const secs = Math.floor(seconds % 60);

  // Format hours, minutes, and seconds to ensure they are two digits
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = secs.toString().padStart(2, "0");

  // Join the formatted components into hh:mm:ss format
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const FormatSize = (sizeInBytes) => {
  // If sizeInBytes is an object, extract the sizeInBytes property
  const bytes =
    typeof sizeInBytes === "object" && "sizeInBytes" in sizeInBytes
      ? sizeInBytes.sizeInBytes
      : sizeInBytes;

  // Validate bytes
  if (typeof bytes !== "number" || isNaN(bytes)) {
    console.error("Invalid sizeInBytes value:", bytes);
    return "Not available";
  }

  // Convert bytes to MB
  const sizeInMB = bytes / (1024 * 1024); // Corrected conversion factor (1 MB = 1024 * 1024 bytes)

  if (sizeInMB < 1000) {
    // If size is less than 1000 MB, format it in MB
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    // If size is 1000 MB or more, convert to GB
    const sizeInGB = sizeInMB / 1024; // Convert MB to GB
    return `${sizeInGB.toFixed(2)} GB`;
  }
};
