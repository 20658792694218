import React from "react";
import { Box, Typography } from "@mui/material";
import {theme} from "../../../theme/theme";

const Counter = (props) => {

    return (
      <Box width="100%" m="30px 30px">
        <Box display="flex" justifyContent="center">
          <Box>
            <Typography
              variant="h4"
              fontWeight={theme.fontWeight.light}
              sx={{ color: theme.colors.text.primary }}
            >
              {props.count}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mt="2px">
          <Typography variant="h5" sx={{ color: theme.colors.text.primary }}>
            {props.title}
          </Typography>
        </Box>
      </Box>
    );
  }

export default Counter
