import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAlertActions, useLoadingActions } from "@/store";
import "react-datepicker/dist/react-datepicker.css";
import {
  SignInContainer,
  FormContainer,
  FormWrapper,
  AuthLogoBlock,
  AuthTitleBlock,
  TitleText,
  ButtonWrapper,
  SubmitButton,
  ButtonNoBgWrapper,
  ButtonNoBg,
  ErrorText,
} from "./Auth.elements";
import { doSignInWithEmailAndPassword } from "../../../firebase/auth";
import { TextField } from "@mui/material";
import PasswordField from "@/components/PasswordField";
import { Logo } from "@/components/Logo/Logo";

const SignInForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const handleResetPassword = () => {
    navigate("/resetpassword", { replace: true });
  };

  const [currentPasswordError, setCurrentPasswordError] = useState();

  const { showLoading, hideLoading } = useLoadingActions();
  const { hideAlert } = useAlertActions();

  const onSubmit = async (data) => {
    try {
      showLoading();
      hideAlert();
      // Attempt to sign in with email and password
      const userCredential = await doSignInWithEmailAndPassword(
        data.email,
        data.password
      );
      console.log("Signed in:", userCredential.user);
      // Navigate to dashboard or another route on successful login
      hideLoading();
      navigate("/"); // Adjust according to your routing
    } catch (error) {
      hideLoading();
      setCurrentPasswordError("Your email or password are incorrect. Please try again!")
      // Handle errors here, such as displaying a notification
      console.error("Error signing in:", error);
    }
  };

  return (
    <SignInContainer>
      <FormContainer>
        <AuthLogoBlock>
          <Logo />
        </AuthLogoBlock>
        <AuthTitleBlock>
          <TitleText>SIGN IN</TitleText>
        </AuthTitleBlock>

        {currentPasswordError && (
          <ErrorText>
          {currentPasswordError}
          </ErrorText>
          )
        }

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <TextField
              fullWidth
              label="Email"
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email} // Shows error state if there's a validation error
              helperText={errors.email?.message} // Displays the error message
            />

            <PasswordField
              label="Password" // Field label
              fieldName="password" // Name for the field
              register={register} // Pass the register from useForm
              errors={errors} // Pass the errors from form validation
              validation={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
            />
          </FormWrapper>
          <ButtonWrapper>
            <SubmitButton
              buttonState="auth"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Sign In
            </SubmitButton>
          </ButtonWrapper>
        </form>
        <ButtonNoBgWrapper>
          <ButtonNoBg onClick={handleResetPassword}>
            Forgot/Reset Password
          </ButtonNoBg>
        </ButtonNoBgWrapper>
      </FormContainer>
    </SignInContainer>
  );
};

export default SignInForm;
