import { useAlertState } from "@/store/alertSlice"
import { Alert, Grow, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export const GlobalAlert = () => {
  const { alert } = useAlertState();
  const [prevAlert, setPrevAlert] = useState({});

  useEffect(() => {
    if (!!alert.message) {
      setPrevAlert(alert);
    }
  }, [alert])

  return (
    <Snackbar
      open={!!alert.message}
      TransitionComponent={Grow}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ top: { xs: 80, sm: 80 }, right: { xs: 20, sm: 20 } }}
    >
      <Alert severity={prevAlert.type} variant="filled">
        {prevAlert.message}
      </Alert>
    </Snackbar>
  )}
