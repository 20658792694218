import { UploadProgressIcon } from '@/icons';
import { Badge, Box, IconButton, Popover } from '@mui/material'
import { useState } from 'react';

export const UploadProgress = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent="5" color="secondary">
          <UploadProgressIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box px="10px" py="5px">
          Work in progress
        </Box>
      </Popover>
    </>
  );
}
