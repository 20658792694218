import React from "react";
import {
  SignInContainer,
  AuthTitleBlock,
  TitleText,
  ButtonWrapper,
  SubmitButton,
  AuthIcon,
  IconWraper,
  RegularText,
  FormContainer
} from "../../../AuthForm/AuthSteps/Auth.elements";
import { CheckCircle } from "@/icons";

const ChangePasswordConfirmation = (props) => {
  return (
    <SignInContainer>
        <IconWraper>
        <AuthIcon as={CheckCircle} />
        </IconWraper>
        <FormContainer>
        <AuthTitleBlock>
          <TitleText>PASSWORD CHANGED!</TitleText>
        </AuthTitleBlock>
        <RegularText>
        Your password has been changed successfully.
        </RegularText>
        </FormContainer>
        <ButtonWrapper>
          <SubmitButton buttonState="auth" onClick={props.close}>
          Continue</SubmitButton>
        </ButtonWrapper>
    </SignInContainer>
  );
};

export default ChangePasswordConfirmation;
