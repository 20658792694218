import React from "react";
import AddNewUser from "@/components/AddUser/AddNewUser";
import {
    ModalOverlay,
    ModalContainer,
    ModalBody,
    ModalCloseButton,
  } from "../../components/Util/Modal.elements";

const AddNewUserPage = (props) => {
  return props.show ? (
    <ModalOverlay>
      <ModalContainer>
        <ModalBody>
          <ModalCloseButton onClick={props.handleClose}>
            &times;
          </ModalCloseButton>
          <AddNewUser onClose={props.handleClose} />
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  ) : null;
};

export default AddNewUserPage;
