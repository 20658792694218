import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setLoading, showLoading, hideLoading } = loadingSlice.actions;

export const useLoadingState = () => useSelector((state) => state.loading);
export const useLoadingActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  }), [dispatch]);
}

export default loadingSlice.reducer;
