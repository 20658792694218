import { auth } from "./firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  updateProfile,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";

export const doCreateUserWithEmailAndPassword = async (
  name,
  email,
  password,
  originalUser
) => {
  try {
    // Create user with email and password
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Update user profile with additional details like 'name'
    await updateProfile(userCredential.user, {
      displayName: name, // Set user's display name
      // You can add other profile details here if needed
    });

    console.log("User created with name:", userCredential.user.displayName);

    // Send password reset email
    await sendPasswordResetEmail(auth, email);

    console.log("Password reset email sent to:", email);

    return userCredential;
  } catch (error) {
    console.error("Error in creating user:", error);
    throw new Error(error.message);
  }
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  //result.user
  return result;
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doDeleteUser = async (user) => {
  try {
    // Delete user from Firebase Authentication
      await deleteUser(user);
      console.log(`User with ID ${user.id} deleted from Firebase Authentication.`);

    // Delete the user's document from Firestore
    const userDoc = doc(firestore, "users", user.id);
    await deleteDoc(userDoc);
    console.log(`User document with ID ${user.id} deleted from Firestore.`);

  } catch (error) {
    console.error("Error in deleting user:", error);
    throw new Error(error.message);
  }
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = async (oldPassword, newPassword) => {
  const user = auth.currentUser;

  try {
    // Create a credential object with the user's email and the old password
    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    // Re-authenticate the user before changing the password
    await reauthenticateWithCredential(user, credential);

    // Change the password
    await updatePassword(user, newPassword);
  } catch (error) {
    console.error("Failed to change password: ", error.message);
    throw error;
  }
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};
