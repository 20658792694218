import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { FormButton } from "../UploadForm.elements";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormContainer,
  FormWrapper,
  FormGroup,
  FormLabel,
  FormError,
  Input,
  TextArea,
  MetadataTitleBlock,
  MetadataTitle,
  MetadataSubTitle,
  CustomDatePickerInput,
  ButtonWrapper,
  Select
} from "./MetadataForm.elements";
import {UploadTitleBlock } from "../UploadForm.elements";
import { TitleText } from "../../Util/Modal.elements";
import countriesData from "../../Util/countries.json";
import statesData from "../../Util/states.json";
import nexstarAffilatesData from "../../Util/nexstarAffilatesData.json";

const MetadataForm = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [affilates, setAffilates] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize countries and affilates from JSON
    setCountries(countriesData);
    setAffilates(nexstarAffilatesData);
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      // Update states based on selected country
      setStates(statesData[selectedCountryCode] || []);
      setValue("state", ""); // Reset state when country changes
    }
  }, [selectedCountryCode, setValue]);

  const handleBack = () => {
    navigate("/videoUpload");
  };

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    console.log("Country code is: ", countryCode);
    const countryName = e.target.options[e.target.selectedIndex].text;
    console.log("Country name is: ", countryName);
    
    setSelectedCountryCode(countryCode);
    setSelectedCountryName(countryName);
    
    setValue("country", countryCode); // Update react-hook-form value
    clearErrors("country"); // Clear error when user selects a valid option
  };

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      country: selectedCountryName, // Use the country name in submission
    };
    props.uploadMetadata(formattedData);
    console.log("Form submitted:", formattedData);
  };

  return (
    <FormContainer>
      <UploadTitleBlock>
        <TitleText textColor="grey">UPLOAD CLIPS:</TitleText>
        <TitleText textColor="green">STEP 2/2</TitleText>
      </UploadTitleBlock>
      <MetadataTitleBlock>
        <MetadataTitle>DESCRIBE YOUR FILES AND BEGIN UPLOAD</MetadataTitle>
        <MetadataSubTitle>*required field</MetadataSubTitle>
      </MetadataTitleBlock>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <FormGroup>
            <FormLabel>Package Name*</FormLabel>
            <FormError>{errors.packageName?.message}</FormError>
            <Input
              {...register("packageName", {
                required: "Package Name is required",
                maxLength: {
                  value: 80,
                  message: "Package Name must be less than 80 characters long",
                },
              })}
              placeholder="Enter the package name"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Description*</FormLabel>
            <FormError>{errors.description?.message}</FormError>
            <Input
              {...register("description", {
                required: "Description is required",
                maxLength: {
                  value: 200,
                  message: "Package Name must be less than 200 characters long",
                },
              })}
              placeholder="Enter the description"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Country*</FormLabel>
              <FormError>{errors.country?.message}</FormError>
            <Select
              {...register("country", {
                required: "Country is required",
              })}
              onChange={handleCountryChange}
              defaultValue=""
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <FormLabel>State*</FormLabel>
            <FormError>{errors.state?.message}</FormError>
        
            <Select
              {...register("state", {
                required: "State is required",
              })}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.code} value={state.name}>
                  {state.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <FormLabel>City*</FormLabel>
            <FormError>{errors.city?.message}</FormError>
            <Input
              {...register("city", {
                required: "City is required",
              })}
              placeholder="Enter the city"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Shot Date*</FormLabel>
            {errors.dateShot && (
              <FormError>{errors.dateShot.message}</FormError>
            )}
            <Controller
              name="dateShot"
              control={control}
              defaultValue=""
              rules={{ required: "Date is required" }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(dateShot) => field.onChange(dateShot)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select a date"
                  customInput={<CustomDatePickerInput />}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Notes</FormLabel>
            <FormError>{errors.notes?.message}</FormError>
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextArea
                  {...field}
                  placeholder="Add your notes"
                  rows="3"
                  cols="20"
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Nexstar Affiliate*</FormLabel>
            <FormError>{errors.state?.message}</FormError>
        
            <Select
              {...register("affilate", {
                required: "Nexstar Affiliate is required",
              })}
            >
              <option value="">Select Nexstar Affiliate</option>
              {affilates.map((affilate) => (
                <option key={affilate.name} value={affilate.name}>
                  {affilate.name} - {affilate.location}
                </option>
              ))}
            </Select>
          </FormGroup>

        </FormWrapper>
        <ButtonWrapper>
          <FormButton buttonState="previous" onClick={handleBack}>
            Back
          </FormButton>
          <FormButton
            buttonState="next"
            type="submit"
            disabled={Object.keys(errors).length > 0}
          >
            Submit
          </FormButton>
        </ButtonWrapper>
      </form>
    </FormContainer>
  );
};

export default MetadataForm;
