/* eslint-disable react/prop-types */
import React from "react";
import {
  UploaderContainer,
  ProgressBarContainer,
  ProgressBarFill,
  ProgressText,
  ProgressStatusText,
  DeleteButton,
  ProgressProcessBlock,
  ProgressBarWrapper,
} from "./UploadForm.elements";

const ProgressBar = (props) => {
  const isStatusComplete = props.status !== "";
  //const isProcessingComplete = props.progress > 99;

  // Determine the content to render based on progress and status
  const renderContent = () => {
    if (isStatusComplete) {
      return (
        <ProgressStatusText status={props.status}>
          {props.packageName}: {props.status}
          {props.status === "Successfully uploaded" && props.folder && (
            <>
              {" - You could find your upload in "}
              <strong>{props.folder}</strong>
              {" folder"}
            </>
          )}
        </ProgressStatusText>
      );
    }
    // if (isProcessingComplete) {
    //   return (
    //     <>
    //       {/* <ProgressText>{props.packageName}: </ProgressText> */}
    //       <ProgressProcessBlock>
    //       <ProgressText>cloud processing...</ProgressText>
    //       <Loader 
    //       type="spinner-default" 
    //       color="#fff" 
    //       size={30}
    //       style={{ margin: 0, padding: 0 }}
    //       />
    //       </ProgressProcessBlock>
    //       </>
    //   );
    // } 
    else {
      return (
        <>
          <ProgressText>{props.packageName}</ProgressText>
          <ProgressProcessBlock>
            <ProgressText>{props.progress}%</ProgressText>
            <ProgressBarContainer>
              <ProgressBarFill progress={props.progress} />
            </ProgressBarContainer>
            <DeleteButton onClick={props.cancel}>Cancel</DeleteButton>
          </ProgressProcessBlock>
        </>
      );
    }
  };

  return (
    <ProgressBarWrapper>
      <UploaderContainer>{renderContent()}</UploaderContainer>
      {/* {renderContent()} */}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
