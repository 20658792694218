import React, { useState } from "react";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const PasswordField = ({
  register,
  errors,
  fieldName = "password",     // Default field name is 'password'
  label = "Password",          // Default label for the input
  validation = {},             // Default validation is an empty object
  fullWidth = true,            // Allow control over 'fullWidth'
  required = true,             // Default is required
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!errors[fieldName]}>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <OutlinedInput
        id={fieldName}
        label={label}
        type={showPassword ? "text" : "password"}
        {...register(fieldName, {
          required: required ? `${label} is required` : false,  // Optional required validation
          ...validation,  // Spread additional validation rules
        })}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={`toggle ${label.toLowerCase()} visibility`}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <BsEyeSlash /> : <BsEye />}
            </IconButton>
          </InputAdornment>
        }
      />
      <p style={{ color: "red" }}>{errors[fieldName]?.message}</p>
    </FormControl>
  );
};

export default PasswordField;