/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay } from 'react-icons/fa';
import { CardContainer, 
  ThumbnailContainer, 
  VideoThumbnail, 
  PlayOverlay,
  CardContent,
  Title,
  Description,
  MetaInfo,
  DateText,
  Badge,
} from "./Clip.elements"

const ClipCard = ({ file }) => {
    const [playing, setPlaying] = useState(false);
  
    const handlePlayClick = () => {
      setPlaying(true);
    };

    const resolutionLabels = {
      FOUR_K: "4K",
      HD_PLUS: "HD+",
      HD: "<HD"
    };
  
    return (
      <CardContainer>
        <ThumbnailContainer onClick={handlePlayClick}>
          {!playing ? (
            <>
              <VideoThumbnail src={file.previewVideoLink} alt="Video thumbnail" />
              <PlayOverlay>
                <FaPlay color="#fff" size={20} />
              </PlayOverlay>
            </>
          ) : (
            <ReactPlayer
              url={file.videoLink}
              playing={playing}
              controls
              width="100%"
              height="100%"
            />
          )}
        </ThumbnailContainer>
        <CardContent>
          <Title>{file.headline}</Title>
          <Description>{file.description}</Description>
          <MetaInfo>
            <DateText>
            {file.fileName} Processed on {
              new Date(file.processedDate * 1000).toLocaleDateString("en-GB", {
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              }</DateText>
            <Badge type={file.resolution}>{resolutionLabels[file.resolution]}</Badge>
          </MetaInfo>
        </CardContent>
      </CardContainer>
    );
  };
  
  export default ClipCard;