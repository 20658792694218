import React from 'react'
import { CenterWrapper } from '../../components/AuthForm/AuthSteps/Auth.elements'
import SignInForm from '../../components/AuthForm/AuthSteps/SignInForm'

const SignInPage = () => {
  return (
    <CenterWrapper>
      <SignInForm />
    </CenterWrapper>
  )
}

export default SignInPage
