import styled from "@emotion/styled";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
`;

export const ModalContainer = styled.div`
  background: #3a3a3f;
  //padding: 20px;
  border-radius: 3px;
  max-width: 80%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100vw; // Full width on mobile
    height: 100vh; // Full height on mobile
    max-width: 100%; // Override the max-width on mobile
    max-height: 100%; // Override the max-height on mobile
    border-radius: 0; // Remove border radius for fullscreen look
    padding: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.h2`
  margin: 0;
`;

export const ModalBody = styled.div`
  flex: 1; // Make the body take up the remaining space
  overflow-y: auto; // Allow scrolling if content exceeds height
  //padding: 20px;
  display: flex; // Flexbox to ensure the content fills the space
  flex-direction: column; // Stack the elements vertically
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalCloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  color: #aeaeb0;
  position: absolute;
  top: 0px;
  right: 10px; 
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 32px;
    top: 10px;
    right: 24px; 
  }
`;

export const TitleText = styled.div`
  //padding-left: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.2rem;
  color: ${({ textColor }) => {
    switch (textColor) {
      case "grey":
        return "#AEAEB0";
      case "green":
        return "#40A31E";
      default:
        return "#fff";
    }
  }};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
