import styled from "@emotion/styled";
import { Link, NavLink as RouterNavLink } from "react-router-dom";

export const NavPlaceholder = styled.div`
  flex-basis: 0;
  width: 0;
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-basis: 363px;
    width: 363px;
  }
`;
export const Nav = styled.nav`
  width: 100vw;
  height: 100dvh;
  translate: ${({ isMobileMenuOpen }) => isMobileMenuOpen ? '0 0 ' : '-100% 0'};
  transition: translate 0.2s;
  background: ${({ theme }) => theme.palette.primary.dark};
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: inherit;
    height: auto;
    translate: 0;
  }
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 36px 40px;
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  
  @media screen and (max-width: 1008px) {
    margin: auto;
    }
`;

export const NavIcon = styled.span``;

export const NavLink = styled(RouterNavLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px 12px;
  border-radius: 6px;
  color: #F7FAFAA6;

  &:hover, &.active {
    background-color: #29384D;
    color: white;

    ${NavIcon} {
      color: #F5C754;
    }
  }
`;

export const NavDivider = styled.hr`
  border: none;
  border-top: 1px solid #29384D;
  margin: 8px 0;
`;
