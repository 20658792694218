import React from "react";
import { useLocation } from "react-router-dom";
import VideoUpload from "./UploadSteps/VideoUpload/VideoUpload";
import MetadataForm from "./UploadSteps/MetadataForm";

import {
  ModalOverlay,
  ModalContainer,
  ModalBody,
  ModalCloseButton,
} from "../Util/Modal.elements";

const UploadInputGroup = (props) => {
  const location = useLocation();

  const renderContent = () => {
    if (location.pathname === "/videoUpload") {
      return <VideoUpload onFilesUpdate={props.onFilesUpdate} />;
    } else if (location.pathname === "/metadataForm") {
      return <MetadataForm uploadMetadata={props.uploadMetadata} />;
    }
    return null; // Fallback if the path doesn't match
  };

  return props.show ? (
        <ModalOverlay>
          <ModalContainer>
            <ModalBody>
              <ModalCloseButton onClick={props.handleClose}>&times;</ModalCloseButton>
              {renderContent()}
            </ModalBody>
          </ModalContainer>
        </ModalOverlay>
      ) : null;

};

export default UploadInputGroup;
