import http from "./http-common";
import { getAuthToken } from "./authService";

const getStats = async (props) => {
    const token = await getAuthToken();
    console.log("User token is: ", token);
  
    const params = {
        page: 0,
        size: props.pageSize ? props.pageSize : 10,
      };
  
      if (props.contributors) {
        params.contributors = props.contributors;
      }
      if (props.fromDate) {
        params.from = props.fromDate;
      }
      if (props.toDate) {
        params.to = props.toDate;
      }
  
    return http.get(`api/v1/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
  };

  const StatService = {
    getStats,
  };
  
  export default StatService;