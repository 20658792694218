import React from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormWrapper } from "../../UploadForm/UploadSteps/MetadataForm.elements";
import { useAlertActions, useLoadingActions } from '@/store';
import {
  SignInContainer,
  FormContainer,
  SubmitButton,
  BackNavIcon,
  RegularText,
  TitleText,
  ButtonWrapper,
  AuthTitleBlock,
} from "./Auth.elements";
import { doPasswordReset } from "../../../firebase/auth";
import { TextField } from "@mui/material";
import { IoArrowBack } from "react-icons/io5";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { showLoading, hideLoading } = useLoadingActions();
  const { showAlert } = useAlertActions();

  const onSubmit = async (data) => {
    try {
      showLoading();
      await doPasswordReset(data.email);
      hideLoading();
      navigate("/passwordsent", {
        state: { email: data.email }
      });
      showAlert(
        "success",
        "Password reset email sent. Please check your inbox."
      );
    } catch (error) {
      hideLoading();
      showAlert(
        "error",
        "Failed to send password reset email: " + error.message
      );
    }
  };

  return (
    <SignInContainer>
      <BackNavIcon onClick={() => navigate("/signin")}>
          <IoArrowBack />
        </BackNavIcon>
      <FormContainer>
        <AuthTitleBlock>
          <TitleText>FORGOT PASSWORD</TitleText>
        </AuthTitleBlock>

        <RegularText>
        No worries! Enter your email address below, and we’ll
        send you a link to reset your password.
        </RegularText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <TextField
              fullWidth
              label="Email"
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email} // Shows error state if there's a validation error
              helperText={errors.email?.message} // Displays the error message
            />
          </FormWrapper>

          <ButtonWrapper>
            <SubmitButton
              buttonState="auth"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </form>
      </FormContainer>
    </SignInContainer>
  );
};

export default ForgotPasswordForm;
