/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { usePackagesActions, usePackagesState } from "@/store";
import DefaultPackageSection from "../../components/PackagesSection/DefaultPackageSection";
import {
  FormatRemainingTime,
  FormatSize,
} from "../../components/Util/Utils.elements";
import { DashboardContainer, StatsTitle } from "../Stats/Counter/CountersBlock.elements";

const DashboardPackageGroupSelection = ({ contributorId }) => {
  const { packages }  = usePackagesState();
  const { fetchPackages } = usePackagesActions();

  const currentPage = 0; // Current page
  const pageSize = 3; // Number of items per page

  useEffect(() => {
    fetchPackages({ contributors: contributorId, currentPage, pageSize });
  }, [contributorId, fetchPackages]);

  const UploadSpeed = (props) => {
    // Extract properties
    const { totalSize, uploadTimeProvider, uploadTimeServer } = props;

    // Calculate elapsed time in seconds
    const elapsedTimeSeconds = (uploadTimeProvider - uploadTimeServer) / 1000;

    // Convert total size to MB
    const totalSizeMB = totalSize / (1024 * 1024); // Convert bytes to megabytes

    // Calculate speed in MB/sec
    const speedMBPerSec =
      elapsedTimeSeconds > 0 ? totalSizeMB / elapsedTimeSeconds : 0;

    return speedMBPerSec.toFixed(2)
  };

  return (
    <DashboardContainer>
      <StatsTitle>
        Recent Uploads
      </StatsTitle>
      {packages.map((pkg) => (
        <DefaultPackageSection
        key={pkg.id}
        packageName={pkg.packageName}
        description={pkg.description}
        dateShot={new Date(pkg.dateShot * 1000).toLocaleDateString("en-GB", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
        status={pkg.status}
        totalSize={
          <FormatSize sizeInBytes={pkg.totalSize} /> || "Not available"
        }
        uploadTimeServer={
          <FormatRemainingTime input={pkg.uploadTimeServer} /> ||
          "Not available"
        }
        uploadTimeProvider={
          <FormatRemainingTime input={pkg.uploadTimeProvider} /> ||
          "Not available"
        }
        uploadSpeed={
          <UploadSpeed
            totalSize={pkg.totalSize}
            uploadTimeProvider={pkg.uploadTimeProvider}
            uploadTimeServer={pkg.uploadTimeServer}
          />
        }
      />
      ))}
    </DashboardContainer>
  );
};

export default DashboardPackageGroupSelection
