import React from 'react'
import PasswordSent from "../../components/AuthForm/AuthSteps/PasswordSent";
import { CenterWrapper } from '../../components/AuthForm/AuthSteps/Auth.elements';

const PasswordSentPage = () => {
  return (
    <CenterWrapper>
      <PasswordSent />
    </CenterWrapper>
  )
}

export default PasswordSentPage