import styled from "@emotion/styled";

export const SearchFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 50px;
  margin-top: 10px;
  margin-bottom: 20px;


  @media (min-width: 1008px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #2b2b2b;
  border: 1px solid #707070;
  margin-top: 5px;
  border-radius: 3px;
  color: #fff;
 /* Added border to match the Select */
  font-size: ${({ theme }) => theme.fontSizes.medium}; /* Ensures consistent font size */
  //box-sizing: border-box; /* Ensures padding and border are included in the width */
  &::placeholder {
    color: #8f8f8f;
  }
`;

export const SearchSelect = styled.select`
  width: 100%;
  padding: 11.5px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #2b2b2b;
  border: 1px solid #707070;
  color: #8f8f8f;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  option {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  appearance: auto;
  &::placeholder {
    color: #8f8f8f;
  }
  `;

export const SearchDatePickerInput = styled.input`
  margin-top: 5px;
  padding: 10px; /* Add padding to the DatePicker input */
  border: 1px solid #707070;
  border-radius: 3px; /* Optional: Rounded corners */
  width: 100%;
  background-color: #2b2b2b;
  color: #fff;
  &::placeholder {
    color: #8f8f8f;
  }
`;

// Container for the entire search form
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// Wrapper for each input group
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Label for inputs
export const Label = styled.label`
  color: #b0b0b0;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;



// Button styling for OK and RESET buttons
export const Button = styled.button`
  padding: 10px 20px;
  background-color: #40A31E;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }

  &:not(:last-child) {
    margin-right: 10px; /* Adds space between buttons */
  }
`;

export const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 35px;
  gap: 10px;
`;
