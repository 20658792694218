/* eslint-disable react/prop-types */
import React from "react";
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormError,
} from "../Util/Form.elements";
import {
  SearchFormWrapper,
  SearchInput,
  SearchDatePickerInput,
  SearchButtonWrapper
} from "../PackagesSection/SearchForm.elements";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { FormButton } from "../UploadForm/UploadForm.elements";

const ClipSearchForm = ({ onSearch }) => {

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleReset = () => {
    reset();
    const resetData = {
      category: null,
      fileName: null,
      fromDate: null,
      toDate: null,
    };
    onSearch(resetData);
  };

  const onSubmit = (data) => {
    console.log("Form Data Submitted: ", data);
    const formattedData = {
      category: data.category ? data.category : null,
      fileName: data.fileName ? data.fileName.trim() : null,
      fromDate: data.fromDate ? data.fromDate : null,
      toDate: data.toDate ? data.toDate : null,
    };
    onSearch(formattedData);
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SearchFormWrapper>
          <FormGroup>
            <FormLabel>Clip Name:</FormLabel>
            <FormError>{errors.fileName?.message}</FormError>
            <SearchInput
              {...register("fileName")}
              placeholder="Enter search term..."
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>From:</FormLabel>
            {errors.dateShot && (
              <FormError>{errors.fromDate.message}</FormError>
            )}
            <Controller
              name="fromDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(fromDate) => field.onChange(fromDate)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select from date"
                  customInput={<SearchDatePickerInput />}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>To:</FormLabel>
            {errors.dateShot && <FormError>{errors.toDate.message}</FormError>}
            <Controller
              name="toDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(toDate) => field.onChange(toDate)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select to date"
                  customInput={<SearchDatePickerInput />}
                />
              )}
            />
          </FormGroup>
          <SearchButtonWrapper>
            <FormButton buttonState="next" type="submit">
              Search
            </FormButton>
            <FormButton
              buttonState="previous"
              type="button"
              onClick={handleReset}
            >
              Reset
            </FormButton>
          </SearchButtonWrapper>
        </SearchFormWrapper>
      </form>
    </FormContainer>
  );
};

export default ClipSearchForm;
