import React, { useEffect, useState } from "react";
import { useContributorsState, usePackagesActions, usePackagesState } from "@/store";
import DefaultPackageSection from "../../components/PackagesSection/DefaultPackageSection";
import {
  FormatRemainingTime,
  FormatSize,
} from "../../components/Util/Utils.elements";
import SearchForm from "../../components/PackagesSection/SearchForm";
import Pagination from "../Util/Pagination";

const PackageGroupSelection = (props) => {
  const { packages, totalPages } = usePackagesState();
  const { fetchPackages } = usePackagesActions();
  const { selectedContributorId } = useContributorsState();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    contributors: null,
    country: null,
    packageName: null,
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    fetchPackages({ page: currentPage, pageSize, contributors: selectedContributorId, ...searchParams });
  }, [fetchPackages, currentPage, pageSize, selectedContributorId, searchParams]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    console.log("newPageSize: ", newSize);
    setPageSize(newSize);
    setCurrentPage(0);
  };

  const handleSearch = (newSearchParams) => {
    setSearchParams(newSearchParams);
    setCurrentPage(0); // Reset to the first page when a new search is performed
  };

  const UploadSpeed = (props) => {
    const { totalSize, uploadTimeProvider, uploadTimeServer } = props;

    const elapsedTimeSeconds = (uploadTimeProvider - uploadTimeServer) / 1000;
    const totalSizeMB = totalSize / (1024 * 1024); // Convert bytes to megabytes
    const speedMBPerSec =
      elapsedTimeSeconds > 0 ? totalSizeMB / elapsedTimeSeconds : 0;

    return speedMBPerSec.toFixed(2);
  };

  return (
    <>
      <SearchForm onSearch={handleSearch} />
      {packages.map((pkg) => (
        <DefaultPackageSection
          key={pkg.id}
          packageName={pkg.packageName}
          description={pkg.description}
          dateShot={new Date(pkg.dateShot * 1000).toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
          status={pkg.status}
          totalSize={
            <FormatSize sizeInBytes={pkg.totalSize} /> || "Not available"
          }
          uploadTimeServer={
            <FormatRemainingTime input={pkg.uploadTimeServer} /> ||
            "Not available"
          }
          uploadTimeProvider={
            <FormatRemainingTime input={pkg.uploadTimeProvider} /> ||
            "Not available"
          }
          uploadSpeed={
            <UploadSpeed
              totalSize={pkg.totalSize}
              uploadTimeProvider={pkg.uploadTimeProvider}
              uploadTimeServer={pkg.uploadTimeServer}
            />
          }
        />
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

export default PackageGroupSelection
