
import { FaBars, FaTimes } from 'react-icons/fa';
import { useAuthState, useMobileMenuState, useMobileMenuActions } from '@/store';
import { Spacer } from '@/globalStyles';
import { UploadProgress, UserProfile, Logo, UploadButton } from "@/components";
import ContributorsSelector from "@/components/Util/ContributorsSelector";
import { HeaderPlaceholder, HeaderContainer, MobileNavIcon } from "./Header.elements";
import { useIsMobile } from '../hooks';


export const Header = (props) => {
  const { handleOpenModal } = props;
  const isMobile = useIsMobile();
  const { isMobileMenuOpen } = useMobileMenuState();
  const { toggleMobileMenu } = useMobileMenuActions();

  const { currentUser: { role } } = useAuthState();
  const isAdmin = role === "admin";
  const isManager = role === "manager";

  return (
    <HeaderPlaceholder>
      <HeaderContainer>

        {isMobile && (
          <MobileNavIcon onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </MobileNavIcon>
        )}

        {(isMobile && isMobileMenuOpen) && <Spacer />}

        {(!isMobile || (isMobile && isMobileMenuOpen)) && <Logo />}
        {(!isMobile || (isMobile && !isMobileMenuOpen)) && (
          <>
            {(isAdmin || isManager) && <ContributorsSelector />}

            <Spacer />

            <UploadButton handleOpenModal={handleOpenModal} />
            <UploadProgress />
            <UserProfile />
        </>
      )}

      </HeaderContainer>
    </HeaderPlaceholder>
  )
};
