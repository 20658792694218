import React from 'react'
import ListUsers from '../../components/AddUser/ListUsers'

const AddUserPage = () => {
   return (
    <div>
        <ListUsers />
    </div>
  )
}

export default AddUserPage