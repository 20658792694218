import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, firestore } from '@/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { doSignOut } from "@/firebase/auth";
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (userId, { dispatch, rejectWithValue }) => {
    try {
      if (!userId) {
        return null;
      }
      const userDoc = doc(firestore, 'users', userId);
      const docSnap = await getDoc(userDoc);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const role = data.role || '';
        console.log('Fetched user role:', role);
        return data;
      } else {
        console.log('No user document found for userId:', userId);
        return null;
      }
    } catch (error) {
      console.error('Failed to fetch user role:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (userId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      await doSignOut(); // Sign out the user
      dispatch(hideLoading());
      dispatch(showAlert("success", "You have successfully signed out"))
      return;
    } catch (error) {
      dispatch(showAlert("error", "There is an error with signing out..."))
      console.error("Error signing out:", error);
      return rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  currentUser: null,
  fetch: {
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  signOut: {
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  }
};

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setFetchStatus: (state, action) => {
      state.fetch.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.fetch.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        const { payload } = action;
        state.currentUser = payload;
        state.fetch.status = 'succeeded';
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.fetch.status = 'failed';
        state.fetch.error = action.payload;
      })

      .addCase(signOut.pending, (state) => {
        state.signOut.status = 'loading';
      })
      .addCase(signOut.fulfilled, (state) => {
        state.currentUser = null
        state.signOut.status = 'succeeded';
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = 'failed';
        state.signOut.error = action.payload;
      });
  },
});

export const { initialize, setCurrentUser, setFetchStatus } = authSlice.actions;

// Thunk to listen for auth state changes
export const listenToAuthChanges = () => (dispatch) => {
  dispatch(setFetchStatus('loading'));

  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    dispatch(fetchUserData(user?.uid));
  });

  return () => unsubscribe();
};

export const useAuthState = () => useSelector((state) => state.auth)
export const useAuthActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    signOut: () => dispatch(signOut()),
  }), [dispatch]);
}

export const useListenToAuthChanges = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = dispatch(listenToAuthChanges());

    // Cleanup on unmount
    return () => {
      unsubscribe();
    };
  }, [dispatch]);
}

export default authSlice.reducer;
