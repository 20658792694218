import { getAuth } from "firebase/auth";

export const getAuthToken = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("No user is currently signed in.");
  }

  const token = await currentUser.getIdToken();
  return token;
};