import React from 'react'
import ChangePasswordForm from './ChangePasswordSteps/ChangePasswordForm';
import { useLocation } from "react-router-dom";
import {
    ModalOverlay,
    ModalContainer,
    ModalBody,
    ModalCloseButton,
  } from "../../Util/Modal.elements";
import ChangePasswordConfirmation from './ChangePasswordSteps/ChangePasswordConfirmation';

const ChangePasswordGroup = (props) => {
    const location = useLocation();

    const renderContent = () => {
      if (location.pathname === "/changepassword") {
        return <ChangePasswordForm />;
      } else if (location.pathname === "/changepasswordconfirmation") {
        return <ChangePasswordConfirmation close={props.handleClose}/>;
      }
      return null; // Fallback if the path doesn't match
    };


    return props.show ? (
        <ModalOverlay>
          <ModalContainer>
            <ModalBody>
              <ModalCloseButton onClick={props.handleClose}>&times;</ModalCloseButton>
              {renderContent()}
            </ModalBody>
          </ModalContainer>
        </ModalOverlay>
      ) : null;
}

export default ChangePasswordGroup