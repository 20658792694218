import styled from "@emotion/styled";

export const CardContainer = styled.div`
  width: 250px;
  background-color: #333;
  color: #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const VideoThumbnail = styled.img`
  width: 100%;
  height: auto;
`;

export const PlayOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing('medium')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContent = styled.div`
  padding: 15px;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 0.9rem;
  margin: 10px 0;
`;

export const MetaInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing('medium')};
`;

export const DateText = styled.span`
  font-size: 0.8rem;
  color: #aaa;
`;

export const Badge = styled.span`
  background-color: ${(props) => {
  switch (props.type) {
    case 'FOUR_K':
      return '#4caf50'; // Green for FOUR_K
    case 'HD_PLUS':
      return '#FF9800'; // Orange for HD_PLUS
    case 'HD':
      return '#f44336'; // Red for HD
    default:
      return '#000'; // Default color if none match
  }
}};
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing('large')};
  padding: ${({ theme }) => theme.spacing('large')};
  //margin: 0 0;
  flex-direction: row;
  margin-left: 10%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
  }
`;
