import styled from "@emotion/styled";
import { Container } from "../../globalStyles";
import { FaCheck } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

export const PackagesContainer = styled(Container)`
padding-top: 30px;
display: flex;
flex-direction: column;
`;

export const PackageContainer = styled(Container)`
  padding-bottom: 5px;
  gap: 3px;
  display: flex;
`;

export const TitleSection = styled.div`
  background: #3A3A3F;
  flex: 1 1 60%;
  display: flex;
  padding: 10px;
  min-height: 50px; 
  max-height: 170px; 
  max-width: 50%;
  overflow: hidden;
`;

export const FeedbackSection = styled.div`
  background: #3A3A3F;
  flex: 1 1 30%; /* Allows it to grow and shrink as needed with a basis of 30% */
  min-height: 50px; 
  max-height: 170px; 
  max-width: 60%;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: center;
`;

export const StatusesContainer = styled.div`
  flex: 1 1 10%; /* Allows it to grow and shrink as needed with a basis of 10% */
  display: flex;
  max-height: 170px;
  flex-direction: column;
  gap: 1px; /* Adds a 1px gap between the items */
`;

export const StatusSection = styled.div`
  padding-left: 5px;
  background: #3A3A3F;
  flex-grow: 1;
  display: flex; /* Ensure that content aligns as needed */
  align-items: center; /* Center content vertically if desired */

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    min-width: 50px; /* Minimize the width for smaller screens */
  }
`;


export const ScrollableText = styled.div`
  flex: 1; /* Takes up available space */
  overflow-y: auto; /* Adds vertical scrollbar when content overflows */
  color: #ffffff; /* Ensures the text is readable against the dark background */
  background-color: #3A3A3F;
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled.div`
  padding-left: 0.625rem;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  color: #1C85C3;
`;

export const NormalText = styled.div`
  padding-left: 0.625rem;
  padding-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: #fff;
`;

export const FeedbackText = styled.div`
  padding-left: 0.625rem;
  padding-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-style: italic;
  color: #DFDFDF;
`;

export const DateText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: auto;
  align-self: flex-end;
  color: #DFDFDF;
`;

export const StatusText = styled.div`
  //padding-left: 5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: #fff;
`;

export const StatusCheckIcon = styled(FaCheck)`
  padding-right: 5px;
  color: #40A31E;
`;

export const StatusDotIcon = styled(GoDotFill)`
padding-right: 5px;
color: #A5A5A5;
`;

// Container for pagination controls
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center; /* Center align pagination controls */
  align-items: center;
  margin: 20px 0; /* Space around pagination controls */
`;

// Style for pagination buttons
export const PaginationButton = styled.button`
  background-color: #3A3A3F; /* Dark background for buttons */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px; /* Padding around text */
  margin: 0 5px; /* Margin between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  
  &:hover {
    background-color: #5A5A5F; /* Slightly lighter on hover */
  }
  
  &:disabled {
    background-color: #6c757d; /* Disabled button color */
    cursor: not-allowed; /* Change cursor when disabled */
  }
`;

// Style for the page size control
export const PageSizeControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Space above page size control */
  margin-bottom: 20px;
`;

export const PageSizeLabel = styled.label`
  margin-right: 10px; /* Space between label and select */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  color: #ffffff; /* White text */
`;

export const PageAmountText = styled.span`
  color: #ffffff;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
`;

export const PageSizeSelect = styled.select`
  background-color: #3A3A3F; /* Dark background */
  color: #ffffff; /* White text */
  border: 1px solid #5A5A5F; /* Border color */
  padding: 5px 10px; /* Padding around text */
  border-radius: 5px; /* Rounded corners */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  cursor: pointer; /* Pointer cursor on hover */
  
  &:focus {
    outline: none; /* Remove default focus outline */
    border-color: #1C85C3; /* Border color on focus */
  }
`;

// Container for the entire search form
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// Wrapper for each input group
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Label for inputs
export const Label = styled.label`
  color: #b0b0b0;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

// Input fields for search, from, and to
export const InputField = styled.input`
  padding: 10px;
  background-color: #2b2b2b;
  color: #fff;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 1rem;
  width: 200px;

  &::placeholder {
    color: #8f8f8f;
  }
`;

// Button styling for OK and RESET buttons
export const Button = styled.button`
  padding: 10px 20px;
  background-color: #40A31E;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }

  &:not(:last-child) {
    margin-right: 10px; /* Adds space between buttons */
  }
`;
