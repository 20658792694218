import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState: {
    isMobileMenuOpen: false,
  },
  reducers: {
    setMobileMenuOpen: (state, action) => {
      state.isMobileMenuOpen = action.payload;
    },
    toggleMobileMenu: (state, action) => {
      state.isMobileMenuOpen =!state.isMobileMenuOpen;
    }
  },
});

export const { setMobileMenuOpen, toggleMobileMenu } = mobileMenuSlice.actions;

export const useMobileMenuState = () => useSelector(state => state.mobileMenu);
export const useMobileMenuActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    setMobileMenuOpen: (isOpen) => dispatch(setMobileMenuOpen(isOpen)),
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  }), [dispatch]);
}

export default mobileMenuSlice.reducer;
